import { useEffect, useState } from "react";

type Props = {
  count: number;
  wrapperClassName?: string;
  imageClassName?: string | ((id: number) => string);
  maxStickerId?: number;
  wrapperElement?: keyof JSX.IntrinsicElements;
  onStickerClick?: (id: number) => void;
};

export default function RandomStickers({
  count,
  wrapperClassName = "",
  imageClassName = "w-16 h-16",
  maxStickerId = 50,
  wrapperElement: WrapperElement = "div",
  onStickerClick,
}: Props) {
  const [stickerIds, setStickerIds] = useState<number[]>([]);

  useEffect(() => {
    const uniqueIds = new Set<number>();
    while (uniqueIds.size < count) {
      uniqueIds.add(Math.floor(Math.random() * maxStickerId) + 1);
    }
    setStickerIds(Array.from(uniqueIds));
  }, [count, maxStickerId]);

  return (
    <WrapperElement className={wrapperClassName}>
      {stickerIds.map((id) => (
        <img
          key={id}
          src={`/images/stickers/${id}.svg`}
          alt={`Sticker ${id}`}
          className={`object-contain ${typeof imageClassName === "function" ? imageClassName(id) : imageClassName}`}
          onClick={() => onStickerClick?.(id)}
          style={{ cursor: onStickerClick ? "pointer" : "default" }}
        />
      ))}
    </WrapperElement>
  );
}
